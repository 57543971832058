import { type MundumData } from "./fetchMundumData";

export const rewardBreakdown = (
  reward: bigint | null,
): Pick<MundumData, 'personalReward' | 'amountGoingToCharity'> => {
  if (reward === null) {
    return {
      amountGoingToCharity: null,
      personalReward: null,
    };
  }

  const charity = reward / 10n;
  const personal = reward - charity;

  return {
    amountGoingToCharity: charity,
    personalReward: personal,
  };
};
