import React, { useMemo } from "react";
import { type ChainId } from "../../contract/contracts";
import { Anchor } from "@mantine/core";

type TransactionLinkProps = {
  network: ChainId;
  txHash: string | null;
};

export const TransactionLink: React.FC<TransactionLinkProps> = ({
  network,
  txHash,
}) => {
  const chainDomain = useMemo(
    (): string => {
      switch (network) {
        case 137: return 'polygonscan.com';
        case 80001: return 'mumbai.polygonscan.com';
      }
    },
    [network],
  );

  const constructedLink = useMemo(
    (): string => {
      return [
        'https://',
        chainDomain,
        '/tx/',
        txHash,
      ].join('');
    },
    [chainDomain, txHash],
  );

  return (
    <Anchor
      href={constructedLink}
      target="_blank"
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '75%' }}
    >
      {constructedLink}
    </Anchor>
  )
};
