import { Flex, Modal, Stepper, Title } from "@mantine/core";
import React, { useCallback, useMemo, useState } from "react";
import { useApplication } from "../../Application";
import { IconPigMoney } from "@tabler/icons-react";
import { type StakingAPI } from "../../contract";
import { Approve } from "./Approve";
import { tokensToPreciseValues } from "../../implementationDetails/preciseValueToTokens";
import { Stake } from "./Stake";
import { Receipt } from "../../Misc/Receipt";
import { useDisclosure } from "@mantine/hooks";
import { MundumLoader } from "../../Misc/MundumLoader";
import { munColors } from "../../Misc/munColors";
import { useModalSize } from "../useModalSize";
import {
  failureDuringStaking,
  failureDuringApproval,
} from "../../notifications";

enum STAKING_PROCESS {
  APPROVE = 0,
  STAKE = 1,
  FINAL = 2,
  FINAL_CHECK = 3,
}

export const DepositStakeModal: React.FC = () => {
  const {
    t,
    selectModal,
    openedModal,
    contractAPIs,
    focusedContract,
    refresh,
    width,
  } = useApplication();

  const closeModal = useCallback(() => selectModal(null), [selectModal]);

  const contract = useMemo((): null | StakingAPI => {
    if (contractAPIs === null) {
      return null;
    }
    if (focusedContract === null) {
      return null;
    }
    return contractAPIs[focusedContract];
  }, [focusedContract, contractAPIs]);

  const [isWaiting, { close: waitOver, open: startWait }] = useDisclosure();
  const [step, setStep] = useState(STAKING_PROCESS.APPROVE);
  const [amountToBeStaked, setAmountToBeStaked] = useState<bigint | null>(null);
  const [tx, setTX] = useState<string | null>(null);

  const approveTokens = useCallback(
    async (tokens: number): Promise<void> => {
      if (contract === null) {
        return;
      }

      try {
        const precise = tokensToPreciseValues(tokens);
        startWait();
        const allowance = await contract.allowance();
        if (allowance < precise) {
          const tx = await contract.approve(precise);
          setAmountToBeStaked(precise);
          // loading
          await tx.wait();
        } else {
          setAmountToBeStaked(precise);
        }
        waitOver();
        setStep(STAKING_PROCESS.STAKE);
      } catch {
        failureDuringApproval(t);
        closeModal();
      }
    },
    [contract, waitOver, startWait, closeModal, t]
  );
  const undoApproval = useCallback(async (): Promise<void> => {
    closeModal(); // TODO
  }, [closeModal]);
  const confirmStaking = useCallback(async (): Promise<void> => {
    if (contract === null) {
      return;
    }
    if (amountToBeStaked === null) {
      return;
    }

    try {
      startWait();
      const staking = await contract.stake(amountToBeStaked);
      await staking.wait();
      waitOver();
      setTX(staking.hash);
      setStep(STAKING_PROCESS.FINAL_CHECK);
      refresh();
    } catch {
      failureDuringStaking(t);
      closeModal();
    }
  }, [contract, amountToBeStaked, refresh, startWait, waitOver, t, closeModal]);
  const modalSize = useModalSize(width);

  return (
    <Modal
      size={modalSize}
      centered
      opened={openedModal === "depositStake"}
      onClose={closeModal}
      title={
        <Flex gap={10} align="center">
          <IconPigMoney />
          <Title order={2}>{t("modals.depositStake.title")}</Title>
        </Flex>
      }
      closeOnClickOutside
      closeOnEscape
    >
      <MundumLoader visible={isWaiting} />
      <Stepper active={step} color={munColors.lightGreen}>
        <Stepper.Step label={t("modals.depositStake.approve_amount")}>
          <Approve approveTokens={approveTokens} closeModal={closeModal} />
        </Stepper.Step>
        <Stepper.Step label={t("modals.depositStake.stake")}>
          <Stake
            amountToBeStaked={amountToBeStaked}
            cancelStaking={undoApproval}
            confirmStaking={confirmStaking}
          />
        </Stepper.Step>
        <Stepper.Step label={t("modals.depositStake.receipt")}>
          <Receipt
            i18nKey={"modals.depositStake.receipt_description"}
            hash={tx}
          />
        </Stepper.Step>
        <Stepper.Completed>
          <Receipt
            i18nKey={"modals.depositStake.receipt_description"}
            hash={tx}
          />
        </Stepper.Completed>
      </Stepper>
    </Modal>
  );
};
