import { createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiProvider, createConfig, http } from "wagmi";
import { type PropsWithChildren, useEffect, useState } from "react";
import { polygon, polygonMumbai } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { walletConnect } from 'wagmi/connectors';

// 1. Get projectID at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_WC_PROJECT_ID || "";

const connector = walletConnect({
  projectId,
  showQrModal: false, // `true` renders two modals above each other, unclosable
});

const wagmiConfig = createConfig({
  chains: [polygon, polygonMumbai],
  transports: {
    [polygon.id]: http(),
    [polygonMumbai.id]: http(),
  },
  connectors: [connector],
});

createWeb3Modal({ wagmiConfig, projectId });
const queryClient = new QueryClient();

export const WalletConfig: React.FC<PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => setReady(true), []);

  return (
    <>
      {ready ? (
        <WagmiProvider config={wagmiConfig}>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </WagmiProvider>
      ) : null}
    </>
  );
};
