import { Temporal } from "@js-temporal/polyfill";
import { ColoredTag } from "../../../Misc/ColoredTag";
import { useMemo } from "react";
import { formatTimeLeft } from "./formatTimeLeft";
import { useApplication } from "../../../Application";

type Props = {
  left: Temporal.Duration;
};

export const TimeLeftPill: React.FC<Props> = ({
  left,
}) => {
  const { t } = useApplication();
  const labels = useMemo(
    () => {
      return {
        days: t('pool.expiration_labels.days'),
        hours: t('pool.expiration_labels.hours'),
        minutes: t('pool.expiration_labels.minutes'),
        seconds: t('pool.expiration_labels.seconds'),
        expired: t('pool.expiration_labels.expired'),
      };
    },
    [t],
  );

  const label = useMemo(
    (): string => formatTimeLeft(left, labels),
    [left, labels],
  );
  const color = useMemo(
    (): 'green' | 'gray' => label === labels.expired ? 'gray' : 'green',
    [label, labels],
  );

  return (
    <ColoredTag color={color}>
      {label}
    </ColoredTag>
  );
};
