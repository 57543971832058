import React from "react";
import { LinkSection } from "./LinkSection";

type Props = {
  textColor: string;
  textSize: string;
};

export const NavigationSection: React.FC<Props> = ({
  textColor,
  textSize,
}) => {
  return (
    <LinkSection
      titleKey="footer.navigation_section.section_title"
      links={[
        {
          key: 'footer.navigation_section.home',
          destination: '/#',
        },
        {
          key: 'footer.navigation_section.about_us',
          destination: 'https://mundum.com/#about-us',
        },
        {
          key: 'footer.navigation_section.roadmap',
          destination: 'https://mundum.com/#roadmap',
        },
        {
          key: 'footer.navigation_section.tokenomics',
          destination: 'https://mundum.com/#tokenomics',
        },
      ]}
      textColor={textColor}
      textSize={textSize}
    />
  );
};
