import { useMediaQuery } from "@mantine/hooks";
import { useMemo } from "react";

type Width = 'sm' | 'md' | 'lg'| 'xl';

export const useWebWidth = (): Width => {
  const isSmall = useMediaQuery('(max-width: 485px)');
  const isTablet = useMediaQuery('(max-width: 800px)');
  const smallerThanDesktop = useMediaQuery('(max-width: 1440px)');

  return useMemo(
    (): Width => {
      if (isSmall) { return 'sm'; }
      if (isTablet) { return 'md'; }
      if (smallerThanDesktop) { return 'lg'; }
      return 'xl';
    },
    [isSmall, smallerThanDesktop, isTablet],
  );
};
