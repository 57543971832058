import { useEffect, useState } from "react";
import { UseWalletClientReturnType, useWalletClient } from "wagmi";
import { makeContractAPIs } from "../contract";
import { type ChainId } from "../contract";
import { useInterval } from "@mantine/hooks";
import { type MundumData } from "./fetchMundumData";
import { useRefreshData } from "./useRefreshData";

const chainId: ChainId = 137;

type APIs = Awaited<ReturnType<typeof makeContractAPIs>>;
export type WalletData = {
  mundumData: MundumData | null;
  ownedMundum: null | bigint;

  contractAPIs: null | APIs;
  chain: ChainId;
  focusedContract: string | null;
  setFocusedContract: (newValue: string | null) => void;

  refresh: () => void;
};

export const useWallet = (
): WalletData => {
  const [APIs, setAPIs] = useState<null | APIs>(null);
  const [focusedContract, setFocusedContract] = useState<string | null>(null);
  const [mundumData, setMundumData] = useState<MundumData | null>(null);
  const [ownedMundum, setOwnedMundum] = useState<null | bigint>(null);

  const { data: walletClient } = useWalletClient() as UseWalletClientReturnType;
  const [chain, setChain] = useState<null | number>(null);

  const refreshData = useRefreshData(
    walletClient,
    focusedContract,
    setFocusedContract,
    chain,
    setChain,
    APIs,
    setAPIs,
    setMundumData,
    setOwnedMundum,
  );

  const interval = useInterval(
    (): void => void refreshData(),
    10000,
  );
  useEffect(
    () => {
      interval.start();
      refreshData();
      return interval.stop;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshData],
  );

  return {
    mundumData,
    ownedMundum,

    setFocusedContract,
    chain: walletClient?.chain.id as ChainId || chainId,
    focusedContract,
    contractAPIs: APIs,

    refresh: refreshData,
  };
};
