import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_translations from "./locales/en.json";
import de_translations from "./locales/de.json";

/**
 * Copied from
 * https://react.i18next.com/latest/using-with-hooks
 */
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: { translation: en_translations },
      de: { translation: de_translations },
    },
  });

export default i18n;
