import { preciseValueToTokens } from "../implementationDetails/preciseValueToTokens";

export const rewardPercentage = (
  reward: bigint,
  stake: bigint,
): string => {
  if (stake === 0n) { return '-'; }

  const rewardReal = preciseValueToTokens(reward);
  const stakeReal = preciseValueToTokens(stake);
  const numeric = (rewardReal / stakeReal * 100).toFixed(2);
  return `+${numeric}%`;
};
