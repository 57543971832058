import * as ethers from "ethers";
import { type ChainId } from "../contracts";
import { type UseWalletClientReturnType } from "wagmi";
import { instantiateContracts } from "./instantiateContracts";

type Result = {
  signerAddress: string;
  contracts: ReturnType<typeof instantiateContracts>;
};

export const connect = async (
  walletClient: Exclude<UseWalletClientReturnType['data'], undefined>,
): Promise<Result> => {
  const { chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new ethers.BrowserProvider(transport, network);
  return initContracts(provider, chain.id as ChainId);
};

const initContracts = async (
  provider: ethers.ethers.BrowserProvider,
  chainId: ChainId,
): Promise<Result> => {
  const signer = await provider.getSigner();
  const signerAddress = await signer.getAddress();

  const contracts = instantiateContracts(chainId, signer);

  return { signerAddress, contracts };
};
