import * as ethers from "ethers";
import { Contract } from "ethers";
import { contracts, type ChainId, mundumContract } from "../contracts";

type ContractGroup = {
  mundum: Contract;
  staking: Record<string, Contract>;
};

export const instantiateContracts = (
  chain: ChainId,
  signer: ethers.JsonRpcSigner,
): ContractGroup => {
  const mundumSpecs = mundumContract[chain];
  const stakingContracts = contracts[chain];

  const group: ContractGroup['staking'] = {};
  for (const spec of stakingContracts) {
    const contract = new Contract(spec.address, spec.abi, signer);
    group[spec.address] = contract;
  }

  return {
    mundum: new Contract(mundumSpecs.address, mundumSpecs.abi, signer),
    staking: group,
  };
};
