import React from "react";
import { Badge, type MantineSize } from "@mantine/core";

type Props = {
  color: string;
  children: React.ReactNode;
  size?: MantineSize;
  onClick?: () => void;
};

export const ColoredTag: React.FC<Props> = ({
  color,
  children,
  size,
  onClick,
}) => {
  return (
    <Badge
      color={color}
      variant="light"
      size={size || 'lg'}
      radius="sm"
      onClick={onClick}
    >
      {children}
    </Badge>
  );
};
