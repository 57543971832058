import { Anchor, Button, Flex, NumberInput, Space, Stack, Text } from "@mantine/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApplication } from "../../Application";
import { munColors } from "../../Misc/munColors";
import { preciseValueToTokens, tokensToPreciseValues } from "../../implementationDetails/preciseValueToTokens";

type ConfirmProps = {
  confirmWithdrawal: (amount: bigint | null) => Promise<void>;
  closeModal: () => void;
}

export const Confirm: React.FC<ConfirmProps> = ({
  confirmWithdrawal,
  closeModal
}) => {
  const { t, mundumData } = useApplication();
  const maxPossible = useMemo(
    (): bigint => mundumData?.stake || 0n,
    [mundumData],
  );
  const maxTokens = useMemo(
    (): number => preciseValueToTokens(maxPossible),
    [maxPossible],
  );

  const [amount, setAmount] = useState<bigint | null>(maxPossible);
  useEffect(
    (): void => setAmount(maxPossible),
    [maxPossible],
  );

  const handleChange = useCallback(
    (raw: string | number): void => {
      const asFloat = typeof raw === 'string' ? parseFloat(raw) : raw;
      if (isNaN(asFloat)) {
        setAmount(null);
        return;
      }

      const realValue = tokensToPreciseValues(asFloat);
      if (maxPossible < realValue) { return; }
      setAmount(realValue);
    },
    [maxPossible],
  );
  const setToMax = useCallback(
    () => setAmount(maxPossible),
    [maxPossible],
  );



  const isWithdrawalValid = useMemo(
    (): boolean => {
      if (amount === null) { return false; }
      if (amount <= 0n) { return false; }
      if (maxPossible < amount) { return false; }
      return true;
    },
    [amount, maxPossible],
  );



  return (
    <Stack>
      <Text>
        {t('modals.withdrawStake.withdrawal_description')}
      </Text>

      <Space h="md"/>

      <NumberInput
        value={preciseValueToTokens(amount || 0n)}
        onChange={handleChange}
        max={maxTokens}
        suffix=" MUN"
        prefix="&nbsp;&nbsp;&nbsp;&nbsp;"
        leftSection={(
          <Anchor onClick={setToMax}>&nbsp;&nbsp;Max</Anchor>
        )}
        clampBehavior="strict"
        min={0}
      />

      <Space h="md"/>

      <Flex gap="5" justify="space-between">
        <Button color="red" variant="subtle" onClick={closeModal}>
          <Text>{t('modals.cancel')}</Text>
        </Button>
        <Button
          variant="gradient"
          gradient={munColors.greenGradient}
          onClick={() => confirmWithdrawal(amount)}
          disabled={!isWithdrawalValid}
        >
          <Text>{t('modals.withdrawStake.confirm')}</Text>
        </Button>
      </Flex>
    </Stack>
  );
};
