import React from "react";
import { useApplication } from "../../Application";

export const WalletConnectButton: React.FC = (
) => {
  const { t } = useApplication();

  return (
    <w3m-button
      balance="hide"
      label={t('connect-cta.buttonLabel')}
      loadingLabel={t('connect-cta.connecting')}
    />
  );
};
