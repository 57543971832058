import React, { useMemo } from "react";
import { PoolCard } from "./PoolCard";
import { Card, Stack, Center, Avatar, Anchor, Group } from "@mantine/core";
import { useApplication } from "../../Application";
import mundum_square from "../../mundum_square.png";
import { type MundumData } from "../../useWallet/fetchMundumData";
import { IconChevronLeft } from "@tabler/icons-react";

export const PoolsOverview: React.FC<{ data: MundumData }> = ({ data }) => {
  const { width, t, setFocusedContract } = useApplication();

  const [cardPadding, top] = useMemo((): [string, string] => {
    switch (width) {
      case "xl":
        return ["xl", "-45px"];
      case "lg":
        return ["xl", "-45px"];
      case "md":
        return ["sm", "-25px"];
      case "sm":
        return ["sm", "-25px"];
    }
  }, [width]);

  return (
    <Card padding={cardPadding} radius="md" style={{ overflow: "visible" }}>
      <Stack gap={0}>
        <Center style={{ position: "relative", top, height: "40px" }}>
          <Avatar src={mundum_square} size={90} />
        </Center>

        <Anchor
          onClick={() => setFocusedContract(null)}
          style={{ position: "relative", top: "-48px" }}
        >
          <Group gap={1}>
            <IconChevronLeft size="1rem" />
            {t("back")}
          </Group>
        </Anchor>

        <PoolCard data={data} />
      </Stack>
    </Card>
  );
};
