import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Slider,
  Space,
  Stack,
  Text,
  Title,
  TitleOrder,
} from "@mantine/core";
import { useApplication } from "../../Application";
import { Temporal } from "@js-temporal/polyfill";
import { formatDate } from "../../implementationDetails/formatDate";
import { TimeLeftPill } from "./TimeLeftPill/TimeLeftPill";
import {
  IconBusinessplan,
  IconChartArrowsVertical,
  IconChartPie,
  IconLock,
  IconLockOpen,
} from "@tabler/icons-react";
import { displayPreciseMundum } from "../../implementationDetails/displayPreciseMundum";
import { useInterval } from "@mantine/hooks";
import { type MundumData } from "../../useWallet/fetchMundumData";
import { contracts } from "../../contract/contracts";

const hack__poolReward = contracts[137].reduce(
  (acc, contract) => ({
    ...acc,
    [contract.address]: BigInt(contract.rewardTotal) * BigInt(1e18),
  }),
  {} as Record<string, bigint>
);

export const PoolCard: React.FC<{ data: MundumData }> = ({ data }) => {
  const { t, width, focusedContract } = useApplication();
  const rewardTotal = displayPreciseMundum(
    focusedContract ? hack__poolReward[focusedContract as any] : data.poolReward
  );

  const [now, setNow] = useState(Temporal.Now.zonedDateTimeISO());
  const interval = useInterval(
    (): void => setNow(Temporal.Now.zonedDateTimeISO()),
    1000
  );
  useEffect(
    () => {
      interval.start();
      return interval.stop;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [timeLeft, progress, hasEnded, notYetStarted] = useMemo((): [
    Temporal.Duration | null,
    number,
    boolean,
    boolean
  ] => {
    const { periodEnd, duration } = data;
    if (periodEnd === null) {
      return [null, 0, false, true];
    }

    const nowToEnd = now.until(periodEnd);
    const hasEnded = periodEnd.epochMilliseconds <= now.epochMilliseconds;
    if (hasEnded) {
      return [nowToEnd, 100, true, false];
    }

    const hoursLeft = Math.floor(nowToEnd.total("hours"));
    const totalHours = duration.total("hours");
    const progress = (1 - hoursLeft / totalHours) * 100;

    return [nowToEnd, progress, false, false];
  }, [now, data]);

  const lockMessage = useMemo((): string => {
    return t(`pool.withdrawal_${data.isLocked ? "locked" : "open"}`);
  }, [data, t]);

  const [order, tz] = useMemo((): [TitleOrder, string] => {
    switch (width) {
      case "xl":
      case "lg":
        return [3, "18px"];
      case "md":
        return [4, "16px"];
      case "sm":
        return [5, "13px"];
    }
  }, [width]);

  return (
    <>
      <Title ta="center" order={order}>
        {t("pool.pool_reward")}: {rewardTotal}
      </Title>

      <Space h="md" />

      <Text ta="center" size={tz}>
        {t("pool.running_on_polygon")}
      </Text>

      <Space h="lg" />

      <Flex justify="space-between">
        <Text size={tz}>{t("pool.pool_lifetime")}</Text>
        <Text size={tz}>
          {data.periodEnd === null
            ? t("pool.not_active")
            : `${t("pool.closes_on")} ${formatDate(data.periodEnd)}`}
        </Text>
      </Flex>
      <Space h={8} />
      <Slider
        value={progress}
        color="green"
        label={null}
        thumbSize={15}
        styles={{
          thumb: { border: "none" },
          root: { cursor: "default" },
          trackContainer: { cursor: "default" },
        }}
      />
      <Space h={8} />
      <Flex justify="end">
        {timeLeft !== null && <TimeLeftPill left={timeLeft} />}
      </Flex>

      <Space h="md" />

      {!hasEnded && !notYetStarted && (
        <Stack gap={3}>
          <TextWithIcon
            size={tz}
            key={1}
            text={`${t("pool.rewards")}: ${displayPreciseMundum(
              data.poolReward
            )}`}
            icon={<IconBusinessplan size={18} color="gold" />}
          />
          <TextWithIcon
            size={tz}
            key={2}
            text={t("pool.pool_apr", { apr_percent: data.apr_percent })}
            icon={<IconChartArrowsVertical size={18} color="gold" />}
          />
          <TextWithIcon
            size={tz}
            key={3}
            text={`${t("pool.staked_token")}: ${displayPreciseMundum(
              data.lockedToken
            )}`}
            icon={<IconChartPie size={18} color="gold" />}
          />
          <TextWithIcon
            size={tz}
            key={4}
            text={lockMessage}
            icon={
              data.isLocked ? (
                <IconLock size={18} color="gold" />
              ) : (
                <IconLockOpen size={18} color="gold" />
              )
            }
          />
        </Stack>
      )}
    </>
  );
};

type TextWithIconProps = {
  icon: React.ReactNode;
  text: string;
  size: string;
};
const TextWithIcon: React.FC<TextWithIconProps> = ({ icon, text, size }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "30px 1fr",
        alignItems: "center",
      }}
    >
      {icon}
      <Text size={size}>{text}</Text>
    </div>
  );
};
