import { Button, Flex, NumberInput, Space, Stack, Text } from "@mantine/core";
import React from "react";
import { useApplication } from "../../Application";
import { displayPreciseMundum } from "../../implementationDetails/displayPreciseMundum";
import { munColors } from "../../Misc/munColors";

type StakeProps = {
  amountToBeStaked: bigint | null;
  cancelStaking: () => Promise<void>;
  confirmStaking: () => Promise<void>;
};

export const Stake: React.FC<StakeProps> = ({
  cancelStaking,
  amountToBeStaked,
  confirmStaking,
}) => {
  const { t } = useApplication();

  return (
    <Stack>
      <Text>
        {t('modals.depositStake.stake_description')}
      </Text>

      <Space h="md"/>

      <NumberInput
        value={displayPreciseMundum(amountToBeStaked)}
        disabled
      />

      <Space h="md"/>

      <Flex gap="5" justify="space-between">
        <Button color="red" variant="subtle" onClick={cancelStaking}>
          <Text>{t('modals.cancel')}</Text>
        </Button>
        <Button
          variant="gradient"
          gradient={munColors.greenGradient}
          onClick={confirmStaking}
        >
          <Text>{t('modals.depositStake.confirm')}</Text>
        </Button>
      </Flex>
    </Stack>
  );
};
