export type Charity = {
  name: string;
  address: string;
};

export const realCharities: Charity[] = [
  { address: '0xC47e3823AE70b38D643AA8a56f3074e454c415e8', name: 'Charity Dirk Rohde' },
  { address: '0x80d5786dDdC80d9fF0eFbA4B122c1cE096a5d3D4', name: 'Aktion B - Brustgesundheit am Niederrhein e.V.' },
  { address: '0xA8E1f4BA64eDD376D93E17A9355a5460fA403144', name: 'East Africa Sunlife Foundation' },
];
