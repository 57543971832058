import { Accordion, Box, Stack, Title } from "@mantine/core";
import React, { type CSSProperties } from "react";
import { useApplication } from "../../Application";
import { useSectionSize } from "../useSectionSize";

type QnA = {
  q: string;
  a: string;
};

const classes: Record<string, CSSProperties> = {
  wrapper: {
    paddingTop: '64px',
    paddingBottom: '64px',
  },
  title: {
    marginBottom: '46px',
  },
  item: {
    borderRadius: '4px',
    borderWidth: '1px',
    borderColor: 'rgb(55, 58, 64)',
  },
};

export const Faq: React.FC = () => {
  const { t } = useApplication();
  const sizing = useSectionSize();

  const faqItems = t('main.faq', { returnObjects: true }) as QnA[];


  return (
    <Box style={sizing}>
      <Stack style={classes.wrapper}>
        <Title ta="center" style={classes.title}>
          Frequently Asked Questions
        </Title>

        <Accordion variant="separated">
          {faqItems.map(qna => {
            return (
              <Accordion.Item
                style={classes.item}
                value={qna.q}
                key={qna.q}
              >
                <Accordion.Control>
                  {qna.q}
                </Accordion.Control>
                <Accordion.Panel>
                  {qna.a}
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Stack>
    </Box>
  );
};
