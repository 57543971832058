import { type MantineGradient } from "@mantine/core";

const darkGreen = '#00643e';
const lightGreen = '#45a247';

export const munColors = {
  darkGreen,
  lightGreen,
  greenGradient: {
    from: darkGreen,
    to: lightGreen,
    deg: 45,
  } satisfies MantineGradient,
  greenGradientCSS: 'linear-gradient(45deg, #00643e, #45a247)',

  bgDark: '#020e00',
  bgGradientCSS: 'linear-gradient(#001f0f, #020e00)',
};
