const defaultExponent = 1000000000000000000n;
const orderOfMagnitude = 18;

export const preciseValueToTokens = (
  rawValue: bigint,
): number => {
  const stringified = rawValue.toString().padStart(99, '0');
  const decimalized = [
    stringified.slice(0, -orderOfMagnitude),
    stringified.slice(-orderOfMagnitude),
  ].join('.');

  return parseFloat(decimalized);
};
export const tokensToPreciseValues = (
  realTokens: number,
): bigint => {
  const stringified = realTokens.toString().split('.');
  if (stringified.length === 1) {
    return BigInt(realTokens) * defaultExponent;
  } else {
    const natural = stringified[0]!;
    const decimal = stringified[1]!;
    const padded = decimal.padEnd(18, '0');
    return BigInt(`${natural}${padded}`);
  }
};
