import { Button, Card, Flex, Stack, Text } from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { useApplication } from "../../Application";
import { KPI } from "./KPI";
import { munColors } from "../../Misc/munColors";
import { useMediaQuery } from "@mantine/hooks";

type Props = {
  focusedContract: string;
};
export const StakeCard: React.FC<Props> = ({ focusedContract }) => {
  const { t, selectModal, contractAPIs, mundumData, width } = useApplication();
  const [canCollect, setCanCollect] = useState<boolean>(false);
  const [canStake, setCanStake] = useState<boolean>(false);
  useEffect((): void => {
    if (contractAPIs === null) {
      setCanCollect(false);
      setCanStake(false);
      return;
    }

    const contract = contractAPIs[focusedContract];

    const fetches = Promise.all([
      contract.periodEndsAt(),
      contract.isLockedUntilPeriodEnds(),
    ]);
    fetches.then(([periodEndsAt, isLockedUntilPeriodEnds]) => {
      const now = Math.floor(Date.now() / 1000);
      const hasEnded = now >= Number(periodEndsAt);
      const hasNotYetStarted = periodEndsAt === 0n;

      if (hasEnded || hasNotYetStarted) {
        setCanCollect(true);
        setCanStake(!isLockedUntilPeriodEnds || hasNotYetStarted);
        return;
      }

      setCanStake(true);
      setCanCollect(!isLockedUntilPeriodEnds);
    });
    // contract.periodEndsAt().then();
    // contract.isLockedUntilPeriodEnds().then(setIsLocked);
  }, [contractAPIs, focusedContract]);

  const yourStake = useMemo(() => {
    const stake = mundumData?.stake || null;
    return (
      <KPI
        amount={stake}
        key="wallet.your_stake"
        title={t("wallet.your_stake")}
      />
    );
  }, [mundumData, t]);
  const yourReward = useMemo(() => {
    const rewardPercentage = mundumData?.rewardPercentage;

    return (
      <KPI
        amount={mundumData?.personalReward || null}
        key="wallet.your_reward"
        title={t("wallet.your_reward")}
        info={
          <Text
            c="green"
            fw={700}
            fz={20}
            style={
              width === "xl"
                ? { alignSelf: "end", marginLeft: "24px" }
                : { marginLeft: "36px" }
            }
          >
            {rewardPercentage}
          </Text>
        }
        amountGoingToCharity={mundumData?.amountGoingToCharity || null}
      />
    );
  }, [t, mundumData, width]);
  const buttons = useMemo(() => {
    return (
      <>
        {canStake && (
          <Button
            variant="gradient"
            gradient={munColors.greenGradient}
            size="lg"
            onClick={() => selectModal("depositStake")}
          >
            <Text fw={700}>{t("wallet.stake_cta")}</Text>
          </Button>
        )}
        <Button
          variant="default"
          size="lg"
          disabled={!canCollect}
          style={{ border: "1px solid rgb(55, 58, 64)", borderRadius: "4px" }}
          onClick={() => selectModal("withdrawStake")}
        >
          <Text fw={700} c="green">
            {t("wallet.withdraw")}
          </Text>
        </Button>
        <Button
          variant="default"
          size="lg"
          disabled={!canCollect}
          style={{ border: "1px solid rgb(55, 58, 64)", borderRadius: "4px" }}
          onClick={() => selectModal("collectReward")}
        >
          <Text fw={700} c="green">
            {t("wallet.collect")}
          </Text>
        </Button>
      </>
    );
  }, [t, canCollect, canStake, selectModal]);

  const smallerThanLaptop = useMediaQuery("(max-width: 1440px)");

  return (
    <Card padding="xl" radius="md">
      {smallerThanLaptop && (
        <Stack gap="xl">
          {yourStake}
          {yourReward}
          {buttons}
        </Stack>
      )}
      {!smallerThanLaptop && (
        <Flex justify="space-between" align="center">
          <Stack gap="xl">
            {yourStake}
            {yourReward}
          </Stack>
          <Stack gap="md">{buttons}</Stack>
        </Flex>
      )}
    </Card>
  );
};
