import {
  Card,
  Center,
  SimpleGrid,
  Space,
  Stack,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useMemo } from "react";
import { ContractTile } from "./ContractTile";
import { useSectionSize } from "../useSectionSize";
import { useApplication } from "../../Application";
import { contracts } from "../../contract/contracts";

export const Contracts: React.FC = () => {
  const theme = useMantineTheme();
  const { width, chain } = useApplication();
  const sizing = useSectionSize();
  const [cardPadding, columns] = useMemo((): [string, number] => {
    switch (width) {
      case "xl":
        return ["xl", 4];
      case "lg":
        return ["xl", 3];
      case "md":
        return ["sm", 2];
      case "sm":
        return ["sm", 1];
    }
  }, [width]);

  const tiles = useMemo((): JSX.Element[] => {
    const stakingContracts = contracts[chain];

    return stakingContracts.map((spec): JSX.Element => {
      return (
        <ContractTile
          key={spec.address}
          address={spec.address}
          locked={spec.locked}
          total={spec.rewardTotal}
          years={spec.durationYears}
        />
      );
    });
  }, [chain]);

  return (
    <Card
      bg={theme.colors.dark[8]}
      style={sizing}
      padding={cardPadding}
      radius="md"
    >
      <Center>
        <Stack>
          <Title order={2}>Staking Pools</Title>
          <Space h={6} />
          <SimpleGrid cols={columns} spacing="lg" verticalSpacing="lg">
            {tiles}
          </SimpleGrid>
        </Stack>
      </Center>
      <Space h={80} />
    </Card>
  );
};
