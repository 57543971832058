import { type ComboboxItem, Select } from "@mantine/core";
import React, { type CSSProperties, useCallback } from "react";
import { type Application, useApplication } from "../Application";

const languageOptions: ComboboxItem[] = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
];
const validOptions = new Set(languageOptions.map(o => o.value));
const isLocale = (raw: string): raw is Application['locale'] => {
  return validOptions.has(raw);
};
const defaultLanguage = languageOptions[0].value;

export const LanguagePicker: React.FC<{ style?: CSSProperties; }> = ({
  style,
}) => {
  const { locale, setLocale } = useApplication();

  const handleChange = useCallback(
    (selected: string | null): void => {
      const selection = selected === null ? defaultLanguage : selected;
      if (selection === locale) { return; }
      if (!isLocale(selection)) { return; }

      setLocale(selection);
    },
    [locale, setLocale],
  );

  return (
    <Select
      allowDeselect={false}
      placeholder="Pick UI language"
      data={languageOptions}
      onChange={handleChange}
      value={locale}
      style={style}
    />
  );
};
