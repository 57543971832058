import React from "react";
import "@mantine/core/styles.css";
import '@mantine/notifications/styles.css';
import { AppShell, MantineProvider } from "@mantine/core";
import { Header } from "./Header";
import { Main } from "./Main";
import { ApplicationContextProvider } from "./Application";
import { Modals } from "./Modals";
import { WalletConfig } from "./WalletConfig";
import { Notifications } from "@mantine/notifications";

const HEADER_HEIGHT = 60;

const App = () => {
  return (
    <WalletConfig>
      <MantineProvider defaultColorScheme="dark">
        <Notifications position="top-center"/>
        <ApplicationContextProvider>
          <AppShell header={{ height: HEADER_HEIGHT }}>
            <Header height={HEADER_HEIGHT} />
            <Main />
            <Modals/>
          </AppShell>
        </ApplicationContextProvider>
      </MantineProvider>
    </WalletConfig>
  );
};

export default App;
