export const mundumAbi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function balanceOf(address account) view returns (uint)",
  "function allowance(address owner, address spender) view returns (uint)",
  "function approve(address spender, uint amount)",
];
export const stakingAbi = [
  "function balanceOf(address account) view returns (uint)",
  "function earned(address account) view returns (uint)",
  "function periodEndsAt() view returns (uint)",
  "function duration() view returns (uint)",
  "function rewardRate() view returns (uint)",
  "function rewardPerToken() view returns (uint)",
  "function isLockedUntilPeriodEnds() view returns (bool)",
  "function totalSupply() view returns (uint)",
  "function stake(uint amount)",
  "function claim(address charity)",
  "function withdraw(uint amount)",
  "function exit(address charity)",
  "function defaultCharity() view returns (address)",
];
