import React from "react";
import { LinkSection } from "./LinkSection";

type Props = {
  textColor: string;
  textSize: string;
};

export const DownloadSection: React.FC<Props> = ({
  textColor,
  textSize,
}) => {
  return (
    <LinkSection
      titleKey="footer.download_section.section_title"
      links={[
        {
          key: 'footer.download_section.whitepaper_en',
          destination: 'https://drive.google.com/file/d/1oKJIfqhw9sCEHZLBC5bWYDz8aPQKOhTf/view',
        },
        {
          key: 'footer.download_section.whitepaper_de',
          destination: 'https://drive.google.com/file/d/1exkndOf0EWzBGxf9nQtnEqcSgxlJI2x_/view',
        },
        {
          key: 'footer.download_section.pitch_deck',
          destination: 'https://drive.google.com/file/d/1XtcnoBy0Q2tVWp6YkQgxi_MhdHOUzkzA/view',
        },
      ]}
      textColor={textColor}
      textSize={textSize}
    />
  );
};
