import { type TFunction } from "i18next";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { type WalletData, useWallet } from "./useWallet/useWallet";
import { type ModalSelection } from "./Modals";
import { useWebWidth } from "./implementationDetails/useWebWidth";

export type Application = {
  locale: 'en' | 'de';
  setLocale: (newLocale: Application['locale']) => void;
  t: TFunction<"translation", undefined>;
  width: ReturnType<typeof useWebWidth>;

  openedModal: ModalSelection;
  selectModal: (newValue: ModalSelection) => void;
} & WalletData;

interface WithChildren {
  children: React.ReactElement;
};

const ApplicationContext = createContext(null as any);
export const useApplication = (): Application => useContext(ApplicationContext);
export const ApplicationContextProvider: React.FC<WithChildren> = ({
  children,
}) => {
  const { t, i18n } = useTranslation();
  const width = useWebWidth();

  const [locale, setLocale] = useState<Application['locale']>('en');
  useEffect(
    (): void => {
      i18n.changeLanguage(locale);
    },
    [locale, i18n],
  );

  const walletData = useWallet();

  const [openedModal, setOpenedModal] = useState<ModalSelection>(null);

  const built: Application = {
    locale,
    setLocale,
    t,
    width,

    openedModal,
    selectModal: setOpenedModal,

    ...walletData,
  };

  return (
    <ApplicationContext.Provider value={built}>
      {children}
    </ApplicationContext.Provider>
  );
};

