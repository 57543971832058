import { Flex, Modal, Stepper, Title } from "@mantine/core";
import React, { useCallback, useMemo, useState } from "react";
import { useApplication } from "../../Application";
import { IconPigMoney } from "@tabler/icons-react";
import { type StakingAPI } from "../../contract";
import { Choose } from "./Choose";
import { Receipt } from "../../Misc/Receipt";
import { useDisclosure } from "@mantine/hooks";
import { Charity, realCharities } from "./Charity";
import { Collect } from "./Collect";
import { MundumLoader } from "../../Misc/MundumLoader";
import { munColors } from "../../Misc/munColors";
import { useModalSize } from "../useModalSize";
import { failureDuringRewardCollection } from "../../notifications";

enum COLLECTING_PROCESS {
  CHOOSE = 0,
  COLLECT = 1,
  FINAL = 2,
  FINAL_CHECK = 3,
}

export const CollectRewardModal: React.FC = () => {
  const {
    t,
    selectModal,
    openedModal,
    contractAPIs,
    focusedContract,
    refresh,
    mundumData,
    width,
  } = useApplication();

  const closeModal = useCallback(() => selectModal(null), [selectModal]);

  const contract = useMemo(
    (): null | StakingAPI => {
      if (contractAPIs === null) { return null; }
      if (focusedContract === null) { return null; }
      return contractAPIs[focusedContract];
    },
    [focusedContract, contractAPIs],
  );

  const availableCharities = useMemo(
    (): Charity[] => {
      if (mundumData === null) { return []; }
      return [
        { address: mundumData.defaultCharity, name: 'Mundum Charity' },
        ...realCharities,
      ];
    },
    [mundumData],
  );

  const [isWaiting, { close: waitOver, open: startWait }] = useDisclosure();
  const [step, setStep] = useState(COLLECTING_PROCESS.CHOOSE);
  const [choice, setChoice] = useState<null | Charity>(null);
  const [tx, setTX] = useState<string | null>(null);

  const handleChoice = useCallback(
    (chosen: Charity): void => {
      setChoice(chosen);
      setStep(COLLECTING_PROCESS.COLLECT);
    },
    [setChoice],
  );
  const collectReward = useCallback(
    async (): Promise<void> => {
      if (contract === null) { return; }
      if (choice === null) { return; }

      try {
        startWait();
        const collecting = await contract.claim(choice.address);
        await collecting.wait();
        refresh();
        setTX(collecting.hash);
        setStep(COLLECTING_PROCESS.FINAL_CHECK);

        waitOver();
      } catch {
        closeModal();
        failureDuringRewardCollection(t);
      }
    },
    [contract, startWait, waitOver, choice, refresh, t, closeModal],
  );
  const modalSize = useModalSize(width);



  return (
    <Modal
      size={modalSize}
      centered
      opened={openedModal === 'collectReward'}
      onClose={closeModal}
      title={
        <Flex gap={10} align="center">
          <IconPigMoney/>
          <Title order={2}>{t('modals.collectReward.title')}</Title>
        </Flex>
      }
      closeOnClickOutside
      closeOnEscape
    >
      <MundumLoader visible={isWaiting}/>
      <Stepper active={step} color={munColors.lightGreen}>
        <Stepper.Step
          label={t('modals.collectReward.choose')}
        >
          <Choose
            availableCharities={availableCharities}
            chooseCharity={handleChoice}
            closeModal={closeModal}
          />
        </Stepper.Step>
        <Stepper.Step
          label={t('modals.collectReward.collect')}
        >
          <Collect
            closeModal={closeModal}
            confirm={collectReward}
          />
        </Stepper.Step>
        <Stepper.Step
          label={t('modals.collectReward.receipt')}
        >
          <Receipt
            i18nKey={'modals.collectReward.receipt_description'}
            hash={tx}
          />
        </Stepper.Step>
        <Stepper.Completed>
          <Receipt
            i18nKey={'modals.collectReward.receipt_description'}
            hash={tx}
          />
        </Stepper.Completed>
      </Stepper>
    </Modal>
  );
};
