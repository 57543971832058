import { Temporal } from "@js-temporal/polyfill";



const orderedKeys = ['days', 'hours', 'minutes', 'seconds'] as const;

export const formatTimeLeft = (
  duration: Temporal.Duration,
  labels: {
    days: string;
    hours: string;
    minutes: string;
    expired: string;
    seconds: string;
  },
): string => {
  if (duration.toString() === 'PT0S') { return '-'; }

  let left = duration;
  const groups: [number, string][] = [];

  for (const key of orderedKeys) {
    const amount = Math.floor(left.total(key));
    if (amount < 0) { return labels.expired; }

    groups.push([amount, labels[key]]);
    left = left.subtract({ [key]: amount });
  }

  let firstIndex = groups.findIndex(group => group[0] !== 0);
  if (firstIndex === -1) {
    console.error('Invalid duration state', duration.toString(), duration);
    return '-';
  }

  const fromFirstNonZero = groups.slice(firstIndex);
  const stringParts = [];
  for (const [amount, label] of fromFirstNonZero) {
    stringParts.push(amount.toString(), label);
  }
  return stringParts.join(' ');
};
