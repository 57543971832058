import { Container, Grid, Space, Stack, useMantineTheme } from "@mantine/core";
import React, { useMemo } from "react";
import { useApplication } from "../Application";
import { munColors } from "../Misc/munColors";
import { AboutTeaser } from "./AboutTeaser";
import { LegalSection } from "./LegalSection";
import { DownloadSection } from "./DownloadSection";
import { NavigationSection } from "./NavigationSection";

export const Footer: React.FC = () => {
  const { width } = useApplication();
  const theme = useMantineTheme();

  const background =  munColors.bgGradientCSS;
  const borderTop = '1px solid #383A3F';

  const textColor = theme.colors.dark[2];
  const textSize = '16px';

  const content = useMemo(
    (): JSX.Element => {
      if (width === 'sm' || width === 'md') {
        return (
          <Stack gap={40} style={{ paddingLeft: '42px' }}>
            <AboutTeaser textColor={textColor} textSize={textSize}/>
            <NavigationSection textColor={textColor} textSize={textSize}/>
            <DownloadSection textColor={textColor} textSize={textSize}/>
            <LegalSection textColor={textColor} textSize={textSize}/>
          </Stack>
        );
      } else {
        return (
        <Grid>
          <Grid.Col span={6}>
            <AboutTeaser textColor={textColor} textSize={textSize}/>
          </Grid.Col>

          <Grid.Col span={2}>
            <NavigationSection textColor={textColor} textSize={textSize}/>
          </Grid.Col>
          <Grid.Col span={2}>
            <DownloadSection textColor={textColor} textSize={textSize}/>
          </Grid.Col>
          <Grid.Col span={2}>
            <LegalSection textColor={textColor} textSize={textSize}/>
          </Grid.Col>
        </Grid>
        );
      }
    },
    [width, textColor, textSize],
  );


  return (
    <footer style={{ background, borderTop }}>
      <Container>
        <Space h="xl" />
        {content}
        <Space h="xl" />
      </Container>
    </footer>
  );
};
