import React, { type CSSProperties, useCallback, useMemo } from "react";
import { type MundumData } from "../../../useWallet/fetchMundumData";
import { Anchor, Button, Card, CloseButton, Image, Text, Title } from "@mantine/core";
import { useApplication } from "../../../Application";
import { useLocalStorage } from "@mantine/hooks";
import styles from "./munShareCTA.module.css";
import image from "./banner.svg";
import { munColors } from "../../../Misc/munColors";

type Props = {
  data: MundumData;
};

export const MunShareCTA: React.FC<Props> = ({
  data,
}) => {
  const { width, focusedContract, t } = useApplication();
  const [dismissed, setDismissed] = useLocalStorage({
    key: `dismissed_${focusedContract}`,
    defaultValue: false,
  });
  const dismiss = useCallback(() => setDismissed(true), [setDismissed]);

  const [cardPadding] = useMemo((): string => {
    switch (width) {
      case "xl":
        return "xl";
      case "lg":
        return "xl";
      case "md":
        return "sm";
      case "sm":
        return "sm";
    }
  }, [width]);



  if (dismissed) { return null; }
  if (data.stake === 0n) { return null; }
  return (
    <Card
      padding={cardPadding}
      radius="md"
      style={{ position: 'relative' }}
      mb={64}
    >
      <CloseButton style={topRight} onClick={dismiss}/>


      <div className={styles.wrapper}>
        <div className={styles.body}>
          <Title className={styles.title}>{t('munShare.more_rewards')}</Title>
          <Text fw={500} fz="lg" mb={5}>{t('munShare.big')}</Text>
          <Text fz="sm" c="dimmed">{t('munShare.small')}</Text>

          <div className={styles.controls}>
            <Anchor
              target="_blank"
              href="https://claim-munshare.mundum.com/"
            >
              <Button className={styles.control} color={munColors.lightGreen}>
                {t('munShare.claim_here_now')}
              </Button>
            </Anchor>
          </div>
        </div>
        <Image src={image} className={styles.image} />
      </div>
    </Card>
  );
};



const topRight: CSSProperties = {
  position: 'absolute',
  right: '15px',
  top: '15px',
};
