import { Box, Flex, Image, Stack, Text } from "@mantine/core";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useApplication } from "../../Application";
import mundum_logo from "../../mundum_square.png";
import { useDisclosure } from "@mantine/hooks";
import { FormattedMundum } from "../../Misc/FormattedMundum";

type KPIProps = {
  title: string;
  amount: bigint | null;
  info?: React.ReactNode;
  amountGoingToCharity?: bigint | null;
};

export const KPI: React.FC<KPIProps> = ({
  amount,
  info,
  title,
  amountGoingToCharity,
}) => {
  const { width, t } = useApplication();
  const titleColor = '#96989C';

  const [isChanging, { open: start, close: end }] = useDisclosure();
  const activeStyles = useMemo(
    (): CSSProperties => {
      return isChanging ? { ...moneyStyle, opacity: 0 } : moneyStyle;
    },
    [isChanging],
  );

  const [displayedAmount, setDisplayedAmount] = useState(amount);
  const [displayedCharity, setDisplayedCharity] = useState(amountGoingToCharity);

  useEffect(
    () => {
      start();
      const timeout = setTimeout(() => {
        setDisplayedAmount(amount);
        setDisplayedCharity(amountGoingToCharity);
        end();
      }, 1000);
      return () => clearTimeout(timeout);
    },
    [amountGoingToCharity, amount, start, end],
  );

  return (
    <Stack gap={0}>
      <Text fw={700} fz={18} c={titleColor}>
        {title}
      </Text>

      {width === 'xl' && (
        <Flex gap="xs" align="center">
          <Image src={mundum_logo} h={28}/>
          <FormattedMundum
              fw={700}
              fz={28}
              lh="38px"
              style={activeStyles}
              mun={displayedAmount}
            />
          {info}
        </Flex>
      )}
      {width !== 'xl' && (
        <Stack gap={0}>
          <Flex gap="xs" align="center">
            <Image src={mundum_logo} h={28}/>
            <FormattedMundum
              fw={700}
              fz={width === 'sm' ? 16 : 28}
              lh={width === 'sm' ? '18px' : '38px'}
              style={activeStyles}
              mun={amount}
            />
          </Flex>
          {info}
        </Stack>
      )}
      {displayedCharity !== undefined && (
        <Flex gap="xs">
          <Box w={28}/>
          <FormattedMundum
            mun={displayedCharity}
            fz={16}
            style={activeStyles}
          />
          {t('wallet.going_to_charity')}
        </Flex>
      )}
    </Stack>
  );
};

const opacityTransition: CSSProperties = {
  transition: 'opacity 1s ease',
};
const moneyStyle: CSSProperties = {
  ...opacityTransition,
  whiteSpace: 'nowrap',
};
