import { type TFunction } from "i18next";
import { notifications } from "@mantine/notifications";

export const failureDuringStakeWithdrawal = (
  t: TFunction,
): void => {
  notifications.show({
    message: t('during_stake_withdrawal'),
    title: t('something_went_wrong'),
    color: 'red',
  });
};
