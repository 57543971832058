export const aprPercent = (
  rewardRate: bigint,
  lockedToken: bigint,
): string => {
  if (lockedToken <= 0n) { return '-'; }

  const secondsPerYear = 31536000n;
  const annualizedRewards = rewardRate * secondsPerYear;
  const number = (annualizedRewards * 100n) / lockedToken;
  return `${number}%`;
};
