import { useMemo, type CSSProperties } from "react";
import { useApplication } from "../Application";

export const useSectionSize = (): CSSProperties => {
  const { width } = useApplication();

  const sizing = useMemo(
    (): CSSProperties => {
      switch (width) {
        case 'xl':
          return {
            width: 'calc(60vw - 200px)',
            marginLeft: 'calc(20vw + 100px)',
            marginRight: 'calc(20vw + 100px)',
          };
        case 'lg':
          return {
            width: 'calc(80vw - 200px)',
            marginLeft: 'calc(10vw + 100px)',
            marginRight: 'calc(10vw + 100px)',
          };
        case 'md': return { margin: '0 80px' };
        case 'sm': return  { margin: '0 10px' };
      }
    },
    [width],
  );

  return sizing;
};
