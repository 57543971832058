import { useMemo } from "react";
import { type Application } from "../Application";

export const useModalSize = (
  width: Application['width'],
): string | number => {
  return useMemo(
    (): string | number => {
      switch (width) {
        case 'xl': return 1200;
        case 'lg': return 600;
        case 'md': return 500;
        case 'sm': return '100vw';
      }
    },
    [width],
  );
};
