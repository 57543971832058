import { Anchor, Stack, Text } from "@mantine/core";
import React from "react";
import { useApplication } from "../Application";

type LinkSectionProps = {
  titleKey: string;
  links: {
    key: string;
    destination?: string;
  }[];

  textColor: string;
  textSize: string;
};

export const LinkSection: React.FC<LinkSectionProps> = ({
  titleKey,
  links,

  textColor,
  textSize,
}) => {
  const { t } = useApplication();

  return (
    <Stack gap="xl">
      <Text c="white" size="20px">{t(titleKey)}</Text>
      <Stack gap="xs">
        {links.map((link): JSX.Element => {
          return (
            <Anchor
              underline="hover"
              c={textColor}
              href={link.destination}
              size={textSize}
              key={link.key}
            >
              {t(link.key)}
            </Anchor>
          );
        })}
      </Stack>
    </Stack>
  );
};
