import { Badge, Group, Text, UnstyledButton } from "@mantine/core";
import React, { useCallback, useMemo } from "react";
import classes from "./contractTile.module.css";
import { IconLock, IconLockOpen } from "@tabler/icons-react";
import { useApplication } from "../../Application";

type Props = {
  address: string;
  locked: boolean;
  years: number;
  total: number;
};

export const ContractTile: React.FC<Props> = ({
  address,
  locked,
  years,
  total,
}) => {
  const { t, setFocusedContract } = useApplication();
  const expired = years === -1;
  const yearsFormatted = useMemo((): string => {
    if (expired) {
      return t("pool.expiration_labels.expired");
    }
    if (years === 1) {
      return `${years} ${t("year")}`;
    } else {
      return `${years} ${t("years")}`;
    }
  }, [years, expired, t]);
  const selectThisContract = useCallback((): void => {
    setFocusedContract(address);
  }, [address, setFocusedContract]);

  return (
    <UnstyledButton
      className={classes.contractTile}
      onClick={selectThisContract}
    >
      <Badge
        mb="sm"
        rightSection={
          locked ? <IconLock size="1rem" /> : <IconLockOpen size="1rem" />
        }
        color={expired ? "gray" : locked ? "yellow" : "blue"}
      >
        {yearsFormatted}
      </Badge>
      <Group gap="5">
        <Text fw="bold">{t("mun-format", { value: total })}</Text>
        <Text c="dimmed">MUN</Text>
      </Group>
    </UnstyledButton>
  );
};
