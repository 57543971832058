import { Anchor, Card, Image, SimpleGrid, Space, Text, Title } from "@mantine/core";
import React, { type CSSProperties, useMemo } from "react";
import { useApplication } from "../../Application";
import walletconnect_logo from "../../walletconnect_logo.svg";
import { WalletConnectButton } from "../../Misc/WalletConnectButton";

const none = { display: 'none' };

export const FallbackCTA: React.FC = () => {
  const { width, t, setFocusedContract } = useApplication();

  const [cardPadding, mobile, desktop] = useMemo(
    (): [string, CSSProperties | undefined, CSSProperties | undefined] => {
      switch (width) {
        case 'xl': return ['xl', none, undefined];
        case 'lg': return ['xl', none, undefined];
        case 'md': return ['sm', undefined, none];
        case 'sm': return ['sm', undefined, none];
      }
    },
    [width],
  );



  return (
    <Card
      padding={cardPadding}
      radius="md"
    >
      <Anchor
          onClick={() => setFocusedContract(null)}
          style={{ position: 'relative', top: '-12px' }}
        >
          {t('back')}
      </Anchor>

      <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
        <Image src={walletconnect_logo} style={mobile}/>
        <div>
          <Title fw={900} fz={34}>
            {t('connect-cta.headline')}
          </Title>
          <Text c="dimmed" size="lg">
            {t('connect-cta.paragraph')}
          </Text>
          <Space h={32}/>
          <WalletConnectButton/>
        </div>
        <Image src={walletconnect_logo} style={desktop}/>
      </SimpleGrid>
    </Card>
  );
};
