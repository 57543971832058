import { Button, Flex, Stack, Text } from "@mantine/core";
import React from "react";
import { useApplication } from "../../Application";
import { munColors } from "../../Misc/munColors";

type CollectProps = {
  closeModal: () => void;
  confirm: () => void;
};

export const Collect: React.FC<CollectProps> = ({
  closeModal,
  confirm,
}) => {
  const { t } = useApplication();

  return (
    <Stack>
      <Text>
        {t('modals.collectReward.collect_description')}
      </Text>

      <Flex gap="5" justify="space-between">
        <Button color="red" variant="subtle" onClick={closeModal}>
          <Text>{t('modals.cancel')}</Text>
        </Button>
        <Button
          gradient={munColors.greenGradient}
          variant="gradient"
          onClick={confirm}
        >
          <Text>{t('modals.collectReward.confirm')}</Text>
        </Button>
      </Flex>
    </Stack>
  );
};
