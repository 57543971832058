import React, { useMemo } from "react";
import { useApplication } from "../Application";
import { Button, Image, Tooltip } from "@mantine/core";
import { displayPreciseMundum } from "../implementationDetails/displayPreciseMundum";
import polygon_logo from "../polygon-logo.svg";

export const HeaderWallet: React.FC = () => {
  const { ownedMundum, chain, t } = useApplication();

  const chainName = useMemo(
    (): string => {
      switch (chain) {
        case 137: return 'Mainnet';
        case 80001: return 'Testnet (Mumbai)';
      }
    },
    [chain],
  );

  return (
    <Tooltip
      label={t('balance_tooltip', { net: chainName })}
    >
      <Button
        variant="default"
        leftSection={<Image src={polygon_logo} h={20}/>}
      >
        {displayPreciseMundum(ownedMundum)}
      </Button>
    </Tooltip>
  );
};
