import { Image, Stack, Text } from "@mantine/core";
import React from "react";
import mundum_logo from "../mundum.svg";
import { useApplication } from "../Application";

type AboutTeaserProps = {
  textColor: string;
  textSize: string;
};

export const AboutTeaser: React.FC<AboutTeaserProps> = ({
  textColor,
  textSize,
}) => {
  const { t } = useApplication();

  return (
    <Stack gap="xs">
      <Image
        src={mundum_logo}
        fit="contain"
        h={46}
        w={132}
        style={{ position: 'relative', top: '-9px' }}
      />
      <Text
        size={textSize}
        c={textColor}
        style={{ paddingRight: '50px' }}
      >
        {t('footer.about_teaser')}
      </Text>
    </Stack>
  );
};
