import { useMemo } from "react";
import { useApplication } from "../Application";
import { DepositStakeModal } from "./DepositStakeModal";
import { WithdrawStakeModal } from "./WithdrawStakeModal";
import { CollectRewardModal } from "./CollectRewardModal";

export const Modals: React.FC = () => {
  const { openedModal } = useApplication();

  const Modal = useMemo(
    (): React.FC | null => {
      switch (openedModal) {
        case null: return null;
        case 'depositStake': return DepositStakeModal;
        case 'withdrawStake': return WithdrawStakeModal;
        case 'collectReward': return CollectRewardModal;
      }
    },
    [openedModal],
  );

  return (
    <>
      {Modal === null ? null : <Modal/>}
    </>
  );
};
