import { Center, Loader, LoadingOverlay, Stack, Title } from "@mantine/core";
import React from "react";
import { useApplication } from "../../Application";
import { munColors } from "../munColors";

export const MundumLoader: React.FC<{ visible: boolean; }> = ({
  visible,
}) => {
  const { t } = useApplication();

  return (
    <LoadingOverlay
      visible={visible}
      zIndex={1000}
      overlayProps={{ radius: "sm", blur: 2 }}
      loaderProps={{
        children: (
          <Stack>
            <Center>
              <Loader color={munColors.lightGreen}/>
            </Center>
            <Title ta="center" fw="700" c={munColors.lightGreen}>
              {t('modals.spinner_subtitle')}
            </Title>
          </Stack>
        ),
      }}
    />
  );
};
