import { Stack, Text } from "@mantine/core";
import React from "react";
import { useApplication } from "../Application";
import { TransactionLink } from "./TransactionLink";

type ReceiptProps = {
  hash: string | null;
  i18nKey: string;
}

export const Receipt: React.FC<ReceiptProps> = ({
  hash,
  i18nKey,
}) => {
  const { t, chain } = useApplication();

  return (
    <Stack align="center">
      <Text ta="center" w="80%" style={{ maxWidth: '800px' }}>{t(i18nKey)}</Text>
      <TransactionLink network={chain} txHash={hash}/>
    </Stack>
  );
};
