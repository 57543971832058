import { Temporal } from "@js-temporal/polyfill";
import { type StakingAPI } from "../contract";
import { aprPercent } from "./aprPercent";
import { rewardBreakdown } from "./rewardBreakdown";
import { rewardPercentage } from "./rewardPercentage";

export type MundumData = {
  defaultCharity: string;
  isLocked: boolean;
  rewardRate: bigint;
  duration: Temporal.Duration;
  periodEnd: Temporal.ZonedDateTime | null;
  poolReward: bigint;
  lockedToken: bigint;

  stake: bigint;
  reward: bigint;
  personalReward: bigint | null;
  amountGoingToCharity: bigint | null;

  rewardPercentage: string;
  apr_percent: string;
};

export const fetchMundumData = async (
  contract: StakingAPI,
): Promise<MundumData> => {
  const [
    defaultCharity,
    reward,
    rewardRate,
    stake,
    lockedToken,
    rawDurationSeconds,
    isLocked,
    rawEndInSeconds,
  ] = await Promise.all([
    contract.defaultCharity(),
    contract.earned(),
    contract.rewardRate(),
    contract.balanceOf(),
    contract.totalSupply(),
    contract.duration(),
    contract.isLockedUntilPeriodEnds(),
    contract.periodEndsAt(),
  ]);


  const { amountGoingToCharity, personalReward } = rewardBreakdown(reward);
  const duration = Temporal.Duration.from({
    seconds: Number(rawDurationSeconds),
  });
  const periodEnd = determinePeriodEnd(rawEndInSeconds);


  return {
    defaultCharity,
    rewardRate,
    lockedToken,
    duration,
    isLocked,
    periodEnd,
    poolReward: rawDurationSeconds * rewardRate,

    stake,
    reward,
    rewardPercentage: rewardPercentage(reward, stake),
    apr_percent: aprPercent(rewardRate, lockedToken),

    amountGoingToCharity,
    personalReward,
  };
};

const determinePeriodEnd = (
  rawEndInSeconds: bigint,
): Temporal.ZonedDateTime | null => {
  if (rawEndInSeconds === 0n) { return null; }

  const nanoseconds = rawEndInSeconds * 1000000000n;
  const currentTimezone = Temporal.Now.timeZoneId();
  const end = new Temporal.ZonedDateTime(nanoseconds, currentTimezone);

  return end;
};
