import { HoverCard, Text } from "@mantine/core";
import React, { type CSSProperties, useMemo } from "react";
import { preciseValueToTokens } from "../../implementationDetails/preciseValueToTokens";
import { displayPreciseMundum } from "../../implementationDetails/displayPreciseMundum";

type Props = {
  mun: bigint | null;
  fw?: number;
  fz?: number;
  lh?: string;
  style?: CSSProperties;
};

export const FormattedMundum: React.FC<Props> = ({
  mun,
  fw,
  fz,
  lh,
  style,
}) => {
  const shortDisplay = useMemo(
    (): string => {
      if (mun === null) { return '-'; }
      const v = preciseValueToTokens(mun);

      return Math.round(v).toString();
    },
    [mun],
  );
  const finalStyles = useMemo(
    (): CSSProperties => {
      return {
        ...style,
        textDecoration: 'underline dotted',
      };
    },
    [style],
  );

  return (
    <HoverCard>
      <HoverCard.Target>
        <Text
          style={finalStyles}
          fw={fw}
          fz={fz}
          lh={lh}
        >
          {shortDisplay} MUN
        </Text>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        {displayPreciseMundum(mun)}
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
