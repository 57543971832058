import React from "react";
import { LinkSection } from "./LinkSection";

type Props = {
  textColor: string;
  textSize: string;
};

export const LegalSection: React.FC<Props> = ({
  textColor,
  textSize,
}) => {
  return (
    <LinkSection
      titleKey="footer.legal_section.section_title"
      links={[
        {
          key: 'footer.legal_section.imprint',
          destination: undefined,
        },
        {
          key: 'footer.legal_section.privacy',
          destination: undefined,
        },
        {
          key: 'footer.legal_section.terms',
          destination: undefined,
        },
        {
          key: 'footer.legal_section.contact',
          destination: undefined,
        },
      ]}
      textColor={textColor}
      textSize={textSize}
    />
  );
};
