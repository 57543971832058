import { Flex, Modal, Stepper, Title } from "@mantine/core";
import React, { useCallback, useMemo, useState } from "react";
import { useApplication } from "../../Application";
import { IconPigMoney } from "@tabler/icons-react";
import { type StakingAPI } from "../../contract";
import { useDisclosure } from "@mantine/hooks";
import { Confirm } from "./Confirm";
import { Receipt } from "../../Misc/Receipt";
import { MundumLoader } from "../../Misc/MundumLoader";
import { munColors } from "../../Misc/munColors";
import { useModalSize } from "../useModalSize";
import { failureDuringStakeWithdrawal } from "../../notifications";

enum WITHDRAWING_PROCESS {
  CONFIRM = 0,
  FINAL = 1,
  FINAL_CHECK = 2,
}

export const WithdrawStakeModal: React.FC = () => {
  const {
    t,
    selectModal,
    openedModal,
    contractAPIs,
    focusedContract,
    refresh,
    width,
  } = useApplication();

  const closeModal = useCallback(() => selectModal(null), [selectModal]);

  const contract = useMemo(
    (): null | StakingAPI => {
      if (contractAPIs === null) { return null; }
      if (focusedContract === null) { return null; }
      return contractAPIs[focusedContract];
    },
    [focusedContract, contractAPIs],
  );

  const [isWaiting, { close: waitOver, open: startWait }] = useDisclosure();
  const [step, setStep] = useState(WITHDRAWING_PROCESS.CONFIRM);
  const [tx, setTX] = useState<string | null>(null);

  const withdrawStake = useCallback(
    async (amount: bigint | null): Promise<void> => {
      if (amount === null) { return; }
      if (contract === null) { return; }

      try {
        startWait();
        const withdrawing = await contract.withdraw(amount);
        await withdrawing.wait();
        refresh();
        setTX(withdrawing.hash);
        setStep(WITHDRAWING_PROCESS.FINAL_CHECK);
        waitOver();
      } catch {
        closeModal();
        failureDuringStakeWithdrawal(t);
      }
    },
    [contract, refresh, startWait, waitOver, closeModal, t],
  );
  const modalSize = useModalSize(width);



  return (
    <Modal
      size={modalSize}
      centered
      opened={openedModal === 'withdrawStake'}
      onClose={closeModal}
      title={
        <Flex gap={10} align="center">
          <IconPigMoney/>
          <Title order={2}>{t('modals.withdrawStake.title')}</Title>
        </Flex>
      }
      closeOnClickOutside
      closeOnEscape
    >
      <MundumLoader visible={isWaiting}/>
      <Stepper active={step} color={munColors.lightGreen}>
        <Stepper.Step
          label={t('modals.withdrawStake.confirm_withdrawal')}
        >
          <Confirm
            confirmWithdrawal={withdrawStake}
            closeModal={closeModal}
          />
        </Stepper.Step>
        <Stepper.Step
          label={t('modals.withdrawStake.receipt')}
        >
          <Receipt
            i18nKey={'modals.withdrawStake.receipt_description'}
            hash={tx}
          />
        </Stepper.Step>
        <Stepper.Completed>
          <Receipt
            i18nKey={'modals.withdrawStake.receipt_description'}
            hash={tx}
          />
        </Stepper.Completed>
      </Stepper>
    </Modal>
  );
};
