import { useCallback } from "react";
import { UseWalletClientReturnType } from "wagmi";
import { makeContractAPIs } from "../contract";
import { MundumData, fetchMundumData } from "./fetchMundumData";

type APIs = Awaited<ReturnType<typeof makeContractAPIs>>;

export const useRefreshData = (
  walletClient: UseWalletClientReturnType['data'],
  focusedContract: null | string,
  setFocusedContract: (focused: null | string) => void,
  chain: null | number,
  setChain: (newValue: null | number) => void,
  APIs: null | APIs,
  setAPIs: (newValue: null | APIs) => void,
  setMundumData: (newValue: MundumData | null) => void,
  setOwned: (newValue: bigint) => void,
): (() => Promise<void>) => {
  const reset = useCallback(
    (resetChain: boolean): void => {
      setFocusedContract(null);
      setAPIs(null);
      setMundumData(null);

      if (resetChain) {
        setChain(null);
      }
    },
    [setFocusedContract, setChain, setAPIs, setMundumData],
  );

  const refresh = useCallback(
    async (): Promise<void> => {
      if (walletClient === undefined) { return reset(true); }
      const currentChain = walletClient.chain.id;

      if (chain !== currentChain) {
        reset(false);
        setChain(currentChain);
        return;
      }

      if (APIs === null) {
        const apis = await makeContractAPIs(walletClient);

        setAPIs(apis);
        return;
      }



      // mundumAvailable actually doesn't have to do anything with any specific
      // staking contract
      const anyContract = Object.values(APIs)[0];
      const owned = await anyContract.mundumAvailable();
      setOwned(owned);



      if (focusedContract === null) {
        setMundumData(null);
        return;
      }



      const contract = APIs[focusedContract];
      const newMundumData = await fetchMundumData(contract);
      setMundumData(newMundumData);
    },
    [walletClient, APIs, chain, focusedContract, reset, setAPIs, setChain, setMundumData, setOwned],
  );

  return refresh;
};
