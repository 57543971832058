import { Button, Flex, NumberInput, Space, Stack, Text } from "@mantine/core";
import React, { useCallback, useMemo, useState } from "react";
import { useApplication } from "../../Application";
import { preciseValueToTokens } from "../../implementationDetails/preciseValueToTokens";
import { munColors } from "../../Misc/munColors";

type ApproveProps = {
  closeModal: () => void;
  approveTokens: (tokens: number) => void;
};

export const Approve: React.FC<ApproveProps> = ({
  closeModal,
  approveTokens,
}) => {
  const { t, ownedMundum } = useApplication();
  const availableTokens = useMemo(
    (): number | null => {
      if (ownedMundum === null) { return null; }
      return preciseValueToTokens(ownedMundum);
    },
    [ownedMundum],
  );

  const [tokens, setTokens] = useState<number | null>(null);
  const isValidAmountOfTokens = useMemo(
    (): boolean => {
      if (tokens === null) { return false; } // nonsense input
      if (tokens <= 0) { return false; } // nonsense input
      if (availableTokens === null) { return false; } // still loading
      if (tokens > availableTokens) { return false; } // overspending
      return true;
    },
    [tokens, availableTokens],
  );

  const handleInputChange = useCallback(
    (raw: string | number): void => {
      const value = typeof raw === 'number' ? raw : parseFloat(raw);
      if (isNaN(value)) { return setTokens(null); }

      const proposed = value === 0 ? null : value;
      if (proposed === tokens) { return; }
      setTokens(proposed);
    },
    [tokens, setTokens],
  );
  const handleConfirmClick = useCallback(
    (): void => {
      if (tokens === null) { return; }
      approveTokens(tokens);
    },
    [approveTokens, tokens],
  );



  return (
    <Stack>
      <Text>
        {t('modals.depositStake.approve_description')}
      </Text>

      <Space h="md"/>

      <NumberInput
        allowNegative={false}
        placeholder={t('modals.depositStake.placeholder')}
        withAsterisk
        onChange={handleInputChange}
      />

      <Space h="md"/>

      <Flex gap="5" justify="space-between">
        <Button color="red" variant="subtle" onClick={closeModal}>
          <Text>{t('modals.cancel')}</Text>
        </Button>
        <Button
          variant="gradient"
          gradient={munColors.greenGradient}
          onClick={handleConfirmClick}
          disabled={!isValidAmountOfTokens}
        >
          <Text>{t('modals.depositStake.confirm')}</Text>
        </Button>
      </Flex>
    </Stack>
  );
};
