import { Button, Flex, Select, Space, Stack, Text } from "@mantine/core";
import React, { useCallback, useState } from "react";
import { useApplication } from "../../Application";
import { type Charity } from "./Charity";
import { munColors } from "../../Misc/munColors";

type ChooseProps = {
  closeModal: () => void;
  chooseCharity: (chosen: Charity) => void;
  availableCharities: Charity[];
};

export const Choose: React.FC<ChooseProps> = ({
  closeModal,
  chooseCharity,
  availableCharities,
}) => {
  const { t } = useApplication();
  const [charity, setCharity] = useState(availableCharities[0]);

  const handleCharitySelect = useCallback(
    (selected: string | null): void => {
      if (selected === null) { return; }

      for (const charity of availableCharities) {
        if (charity.address !== selected) { continue; }
        setCharity(charity);
      }
    },
    [availableCharities],
  );



  return (
    <Stack>
      <Text>
        {t('modals.collectReward.choose_description')}
      </Text>

      <Space h="md"/>

      <Select
        label={t('modals.collectReward.select_charity')}
        data={availableCharities.map(c => ({ label: c.name, value: c.address }))}
        value={charity.address}
        onChange={handleCharitySelect}
        allowDeselect={false}
      />

      <Space h="md"/>

      <Flex gap="5" justify="space-between">
        <Button color="red" variant="subtle" onClick={closeModal}>
          <Text>{t('modals.cancel')}</Text>
        </Button>
        <Button
          gradient={munColors.greenGradient}
          variant="gradient"
          onClick={() => chooseCharity(charity)}
        >
          <Text>{t('modals.collectReward.confirm')}</Text>
        </Button>
      </Flex>
    </Stack>
  );
};
