import { type Temporal } from "@js-temporal/polyfill";

export const formatDate = (
  date: Temporal.ZonedDateTime,
): string => {
  return [
    date.day,
    date.month,
    date.year,
  ].map(v => v.toString().padStart(2, '0')).join('.');
};
