import { preciseValueToTokens } from "./preciseValueToTokens";

export const displayPreciseMundum = (
  bag: bigint | null,
): string => {
  if (bag === null) { return '- MUN'; }

  const tokens = preciseValueToTokens(bag);
  if (tokens > 1) {
    //displayPreciseMundum(1852845812000000000n) => '1.853 MUN'
    //displayPreciseMundum(18528458120000000000n) => '18.528 MUN'
    //displayPreciseMundum(185284581200000000000n) => '185.285 MUN'
    //displayPreciseMundum(1852845812000000000000n) => '1,852.846 MUN'
    //displayPreciseMundum(18528458120000000000000n) => '18,528.458 MUN'
    //displayPreciseMundum(185284581200000000000000n) => '185,284.581 MUN'

    return `${tokens.toLocaleString()} MUN`;
  }

  if (tokens === 0) { return '0 MUN'; }

  //displayPreciseMundum(185284581200000n) => 0.00018528458119999999 MUN
  //displayPreciseMundum(1852845812000000n) => 0.00185284581200000011 MUN
  //displayPreciseMundum(18528458120000000n) => 0.01852845812000000064 MUN
  //displayPreciseMundum(185284581200000000n) => 0.18528458119999999254 MUN
  return `${tokens.toFixed(20)} MUN`;
};
