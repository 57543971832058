import * as ethers from "ethers";

export type StakingAPI = {
  mundumAvailable: () => Promise<bigint>;
  approve: (amount: bigint) => Promise<ethers.TransactionResponse>;
  allowance: () => Promise<bigint>;
  stake: (amount: bigint) => Promise<ethers.TransactionResponse>;
  withdraw: (amount: bigint) => Promise<ethers.TransactionResponse>;
  claim: (charity: string) => Promise<ethers.TransactionResponse>;
  exit: (charity: string) => Promise<void>;
  earned: () => Promise<bigint>;
  balanceOf: () => Promise<bigint>;
  totalSupply: () => Promise<bigint>;
  rewardRate: () => Promise<bigint>;
  rewardPerToken: () => Promise<bigint>;
  duration: () => Promise<bigint>;
  periodEndsAt: () => Promise<bigint>;
  isLockedUntilPeriodEnds: () => Promise<boolean>;
  defaultCharity: () => Promise<string>;
};

export const createStakingAPI = (
  mundumContract: ethers.Contract,
  signerAddress: string,
  stakingContract: ethers.Contract,
  stakingAddress: string,
): StakingAPI => {
  return {
    mundumAvailable: (): Promise<bigint> => {
      return mundumContract.balanceOf(signerAddress);
    },
    approve: (amount: bigint): Promise<ethers.TransactionResponse> => {
      return mundumContract.approve(stakingAddress, amount);
    },
    allowance: (): Promise<bigint> => {
      return mundumContract.allowance(signerAddress, stakingAddress);
    },
    stake: async (amount: bigint): Promise<ethers.TransactionResponse> => {
      return stakingContract.stake(amount);
    },
    withdraw: async (amount: bigint): Promise<ethers.TransactionResponse> => {
      return stakingContract.withdraw(amount);
    },
    claim: async (charity: string): Promise<ethers.TransactionResponse> => {
      return stakingContract.claim(charity);
    },
    exit: async (charity: string): Promise<void> => {
      await stakingContract.exit(charity);
    },
    earned: (): Promise<bigint> => {
      return stakingContract.earned(signerAddress);
    },
    balanceOf: (): Promise<bigint> => {
      return stakingContract.balanceOf(signerAddress);
    },
    totalSupply: (): Promise<bigint> => {
      return stakingContract.totalSupply();
    },
    rewardRate: (): Promise<bigint> => {
      return stakingContract.rewardRate();
    },
    rewardPerToken: (): Promise<bigint> => {
      return stakingContract.rewardPerToken();
    },
    duration: (): Promise<bigint> => {
      return stakingContract.duration();
    },
    periodEndsAt: (): Promise<bigint> => {
      return stakingContract.periodEndsAt();
    },
    isLockedUntilPeriodEnds: (): Promise<boolean> => {
      return stakingContract.isLockedUntilPeriodEnds();
    },
    defaultCharity: (): Promise<string> => {
      return stakingContract.defaultCharity();
    },
  };
};
