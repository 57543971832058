import { AppShell, Space, Stack } from "@mantine/core";
import React from "react"
import { Footer } from "../Footer";
import { Faq } from "./Faq";
import { Coin } from "./Coin";
import { munColors } from "../Misc/munColors";
import { Contracts } from "./Contracts";
import { useApplication } from "../Application";

export const Main: React.FC = () => {
  const { focusedContract } = useApplication();

  return (
    <AppShell.Main>
      <Stack gap="xl" style={{ background: munColors.bgGradientCSS }}>
        <Space h={100}/>

        {focusedContract === null && <Contracts/>}
        {focusedContract !== null && <Coin focusedContract={focusedContract}/>}

        <Faq/>
        <Footer />
      </Stack>
    </AppShell.Main>
  );
};
